import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'HOME',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'イベント概要',
      url: '/summary',
      icon: 'newspaper'
    },
    {
      title: '募集要項',
      url: '/essentials',
      icon: 'pencil'
    },
    {
      title: 'サークル情報',
      url: '/circle',
      icon: 'sunny'
    },
    {
      title: '一般参加要項',
      url: '/general',
      icon: 'star'
    },
    {
      title: 'サークルマニュアル',
      url: '/circle-manual',
      icon: 'book'
    },
    {
      title: '一般参加マニュアル',
      url: '/general-manual',
      icon: 'book'
    },
    // {
    //   title: '搬入要項',
    //   url: '/carry-in',
    //   icon: 'cube'
    // },
    // {
    //   title: 'アフターイベント',
    //   url: '/after-event',
    //   icon: 'musical-notes'
    // },
    {
      title: 'お問い合わせ',
      url: '/contact',
      icon: 'mail'
    },
    {
      title: 'アクセス',
      url: '/access',
      icon: 'location'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
