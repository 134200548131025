import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'summary',
    loadChildren: () => import('./pages/summary/summary.module').then( m => m.SummaryPageModule)
  },
  {
    path: 'essentials',
    loadChildren: () => import('./pages/essentials/essentials.module').then( m => m.EssentialsPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'general',
    loadChildren: () => import('./pages/general/general.module').then( m => m.GeneralPageModule)
  },
  {
    path: 'access',
    loadChildren: () => import('./pages/access/access.module').then( m => m.AccessPageModule)
  },
  {
    path: 'after-event',
    loadChildren: () => import('./pages/after-event/after-event.module').then( m => m.AfterEventPageModule)
  },
  {
    path: 'carry-in',
    loadChildren: () => import('./pages/carry-in/carry-in.module').then( m => m.CarryInPageModule)
  },
  {
    path: 'circle',
    loadChildren: () => import('./pages/circle/circle.module').then( m => m.CirclePageModule)
  },
  {
    path: 'circle-manual',
    loadChildren: () => import('./pages/circle-manual/circle-manual.module').then( m => m.CircleManualPageModule)
  },
  {
    path: 'general-manual',
    loadChildren: () => import('./pages/general-manual/general-manual.module').then( m => m.GeneralManualPageModule)
  },
  // {
  //   path: 'carry',
  //   component: CarryInComponent,
  // },
  // {
  //   path: ':id',
  //   loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
